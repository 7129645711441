import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";

import { Button, Icon } from "@material-ui/core";
import { AccountBoxOutlined, AssignmentTurnedInOutlined, MicNoneOutlined, MonetizationOnOutlined, PersonOutlineOutlined, SchoolOutlined } from "@material-ui/icons";

import { app } from "js/namespaces";
import { headerContainerStyles, headerTextStyles, optionImageStyles, optionNameStyles, optionsButtonStyles, optionsContainerStyles, subHeaderTextStyles } from "js/react/views/Onboarding/MarketingSurveyStyles";

import Container from "./Components/Container";

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const fadeout = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;

const CompleteButton = styled(Button)`
    width: calc(3 * 164px + 2 * 10px);
    align-self: center;
    animation: ${props => props.fadeOut ? css`${fadeout} 0.5s ease-in-out` : css``};

    @media (max-width: 768px) {
        width: 80vw;
    }
`;

const HeaderContainer = styled.div`
    ${headerContainerStyles}
`;

const HeaderText = styled.div`
    ${headerTextStyles}

    @media (max-width: 768px) {
        letter-spacing: -1.5px;
    }
`;

const SubHeaderText = styled.h3`
    ${subHeaderTextStyles}
`;

const OptionsContainer = styled.div`
    ${optionsContainerStyles}
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

const OptionButton = styled(Button)`
    ${optionsButtonStyles}
`;

const OptionImage = styled(Icon)`
    ${optionImageStyles}
`;

const OptionName = styled.span`
    ${optionNameStyles}

    /* Safari-specific styles */
    ${isSafari && `
        margin-top: 5px;
    `}
`;

const PresentationsType = ({ onNext, onBack, prevProgress, currProgress, handleChange, value, firstQuestionValue, paneIdx, prevPaneIdx }) => {
    const [selectedOptions, setSelectedOptions] = useState(value || []);
    const [progress, setProgress] = useState(prevProgress);
    const [fadeOut, setFadeOut] = useState(false);
    const [isCompleteButtonDisabled, setIsCompleteButtonDisabled] = useState(true);

    const calculateDelay = index => {
        return 0.6 + 1 / (1 + Math.exp(-0.25 * index)) * 0.8;
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setProgress(currProgress);
        }, 500);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        setIsCompleteButtonDisabled(selectedOptions.length === 0);
    }, [selectedOptions]);

    const handleOptionClick = (key, option) => {
        let newSelectedOptions;
        if (selectedOptions.includes(option)) {
            newSelectedOptions = selectedOptions.filter(item => item !== option);
        } else if (selectedOptions.length < 3) {
            newSelectedOptions = [...selectedOptions, option];
        } else {
            return;
        }
        setSelectedOptions(newSelectedOptions);
        handleChange(key, newSelectedOptions);
    };

    const handleCompleteButtonClick = () => {
        setFadeOut(true);
        setIsCompleteButtonDisabled(true);
        onNext();
    };

    const handleBackButtonClick = () => {
        setFadeOut(true);
        setTimeout(() => {
            onBack();
        }, 500);
    };

    return (
        <Container showBackButton onBackButtonClick={handleBackButtonClick} progress={progress} prevProgress={prevProgress} paneIdx={paneIdx} prevPaneIdx={prevPaneIdx}>
            <HeaderContainer>
                <HeaderText data-test-id="presentations-type-header" fadeOut={fadeOut} delay={calculateDelay(0)}>What kind of presentations will you create?</HeaderText>
                <SubHeaderText fadeOut={fadeOut} delay={calculateDelay(1)}>Select up to 3</SubHeaderText>
            </HeaderContainer>
            {(firstQuestionValue === "sales" || firstQuestionValue === "other") && (
                <OptionsContainer>
                    <OptionButton
                        selected={selectedOptions.includes("sales-pitches") ? true : false}
                        onClick={() => handleOptionClick("presentationsType", "sales-pitches")}
                        fadeOut={fadeOut}
                        style={{
                            border: selectedOptions.includes("sales-pitches") ? "2px solid #11A9E2" : "0.5px solid #999"
                        }}
                        delay={calculateDelay(2)}
                    >
                        <OptionImage style={{ fontSize: "20px" }}>signal_cellular_alt</OptionImage>
                        <OptionName>Sales pitches</OptionName>
                    </OptionButton>
                    <OptionButton
                        selected={selectedOptions.includes("customer-deliverables") ? true : false}
                        onClick={() => handleOptionClick("presentationsType", "customer-deliverables")}
                        fadeOut={fadeOut}
                        style={{
                            border: selectedOptions.includes("customer-deliverables") ? "2px solid #11A9E2" : "0.5px solid #999"
                        }}
                        delay={calculateDelay(3)}
                    >
                        <OptionImage as={AssignmentTurnedInOutlined} style={{ fontSize: "20px" }}></OptionImage>
                        <OptionName>Customer deliverables</OptionName>
                    </OptionButton>
                    <OptionButton
                        selected={selectedOptions.includes("company-meetings") ? true : false}
                        onClick={() => handleOptionClick("presentationsType", "company-meetings")}
                        fadeOut={fadeOut}
                        style={{
                            border: selectedOptions.includes("company-meetings") ? "2px solid #11A9E2" : "0.5px solid #999"
                        }}
                        delay={calculateDelay(4)}
                    >
                        <OptionImage style={{ fontSize: "20px" }}>groups</OptionImage>
                        <OptionName>Company meetings</OptionName>
                    </OptionButton>
                    <OptionButton
                        selected={selectedOptions.includes("conferences/events") ? true : false}
                        onClick={() => handleOptionClick("presentationsType", "conferences/events")}
                        fadeOut={fadeOut}
                        style={{
                            border: selectedOptions.includes("conferences/events") ? "2px solid #11A9E2" : "0.5px solid #999"
                        }}
                        delay={calculateDelay(5)}
                    >
                        <OptionImage as={MicNoneOutlined} style={{ fontSize: "20px" }}></OptionImage>
                        <OptionName>Conferences/events</OptionName>
                    </OptionButton>
                    <OptionButton
                        selected={selectedOptions.includes("fundraising-decks") ? true : false}
                        onClick={() => handleOptionClick("presentationsType", "fundraising-decks")}
                        fadeOut={fadeOut}
                        style={{
                            border: selectedOptions.includes("fundraising-decks") ? "2px solid #11A9E2" : "0.5px solid #999"
                        }}
                        delay={calculateDelay(6)}
                    >
                        <OptionImage as={MonetizationOnOutlined} style={{ fontSize: "20px" }}></OptionImage>
                        <OptionName>Fundraising decks</OptionName>
                    </OptionButton>
                    <OptionButton
                        selected={selectedOptions.includes("hr-and-training") ? true : false}
                        onClick={() => handleOptionClick("presentationsType", "hr-and-training")}
                        fadeOut={fadeOut}
                        style={{
                            border: selectedOptions.includes("hr-and-training") ? "2px solid #11A9E2" : "0.5px solid #999"
                        }}
                        delay={calculateDelay(7)}
                    >
                        <OptionImage as={AccountBoxOutlined} style={{ fontSize: "20px" }}></OptionImage>
                        <OptionName>HR and training</OptionName>
                    </OptionButton>
                    {firstQuestionValue === "other" && (
                        <OptionButton
                            selected={selectedOptions.includes("personal-use") ? true : false}
                            onClick={() => handleOptionClick("presentationsType", "personal-use")}
                            fadeOut={fadeOut}
                            style={{
                                gridColumn: "2 / 3",
                                border: selectedOptions.includes("personal-use") ? "2px solid #11A9E2" : "0.5px solid #999"
                            }}
                            delay={calculateDelay(8)}
                        >
                            <OptionImage as={PersonOutlineOutlined} style={{ fontSize: "20px" }}></OptionImage>
                            <OptionName >Personal use</OptionName>
                        </OptionButton>
                    )}
                </OptionsContainer>
            )}
            {firstQuestionValue === "marketing" && (
                <OptionsContainer>
                    <OptionButton
                        selected={selectedOptions.includes("sales-pitches") ? true : false}
                        onClick={() => handleOptionClick("presentationsType", "sales-pitches")}
                        fadeOut={fadeOut}
                        style={{
                            border: selectedOptions.includes("sales-pitches") ? "2px solid #11A9E2" : "0.5px solid #999"
                        }}
                        delay={calculateDelay(2)}
                    >
                        <OptionImage style={{ fontSize: "20px" }}>signal_cellular_alt</OptionImage>
                        <OptionName>Sales pitches</OptionName>
                    </OptionButton>
                    <OptionButton
                        selected={selectedOptions.includes("fundraising-decks") ? true : false}
                        onClick={() => handleOptionClick("presentationsType", "fundraising-decks")}
                        fadeOut={fadeOut}
                        style={{
                            border: selectedOptions.includes("fundraising-decks") ? "2px solid #11A9E2" : "0.5px solid #999"
                        }}
                        delay={calculateDelay(3)}
                    >
                        <OptionImage as={MonetizationOnOutlined} style={{ fontSize: "20px" }}></OptionImage>
                        <OptionName>Fundraising decks</OptionName>
                    </OptionButton>
                    <OptionButton
                        selected={selectedOptions.includes("conferences/events") ? true : false}
                        onClick={() => handleOptionClick("presentationsType", "conferences/events")}
                        fadeOut={fadeOut}
                        style={{
                            border: selectedOptions.includes("conferences/events") ? "2px solid #11A9E2" : "0.5px solid #999"
                        }}
                        delay={calculateDelay(4)}
                    >
                        <OptionImage as={MicNoneOutlined} style={{ fontSize: "20px" }}></OptionImage>
                        <OptionName>Conferences/events</OptionName>
                    </OptionButton>
                    <OptionButton
                        selected={selectedOptions.includes("company-meetings") ? true : false}
                        onClick={() => handleOptionClick("presentationsType", "company-meetings")}
                        fadeOut={fadeOut}
                        style={{
                            border: selectedOptions.includes("company-meetings") ? "2px solid #11A9E2" : "0.5px solid #999"
                        }}
                        delay={calculateDelay(5)}
                    >
                        <OptionImage style={{ fontSize: "20px" }}>groups</OptionImage>
                        <OptionName>Company meetings</OptionName>
                    </OptionButton>
                    <OptionButton
                        selected={selectedOptions.includes("customer-deliverables") ? true : false}
                        onClick={() => handleOptionClick("presentationsType", "customer-deliverables")}
                        fadeOut={fadeOut}
                        style={{
                            border: selectedOptions.includes("customer-deliverables") ? "2px solid #11A9E2" : "0.5px solid #999"
                        }}
                        delay={calculateDelay(6)}
                    >
                        <OptionImage as={AssignmentTurnedInOutlined} style={{ fontSize: "20px" }}></OptionImage>
                        <OptionName>Customer deliverables</OptionName>
                    </OptionButton>
                    <OptionButton
                        selected={selectedOptions.includes("hr-and-training") ? true : false}
                        onClick={() => handleOptionClick("presentationsType", "hr-and-training")}
                        fadeOut={fadeOut}
                        style={{
                            border: selectedOptions.includes("hr-and-training") ? "2px solid #11A9E2" : "0.5px solid #999"
                        }}
                        delay={calculateDelay(7)}
                    >
                        <OptionImage as={AccountBoxOutlined} style={{ fontSize: "20px" }}></OptionImage>
                        <OptionName>HR and training</OptionName>
                    </OptionButton>
                </OptionsContainer>
            )}
            {firstQuestionValue === "educator-or-student" && (
                <OptionsContainer>
                    <OptionButton
                        selected={selectedOptions.includes("conferences/events") ? true : false}
                        onClick={() => handleOptionClick("presentationsType", "conferences/events")}
                        fadeOut={fadeOut}
                        style={{
                            border: selectedOptions.includes("conferences/events") ? "2px solid #11A9E2" : "0.5px solid #999"
                        }}
                        delay={calculateDelay(2)}
                    >
                        <OptionImage as={MicNoneOutlined} style={{ fontSize: "20px" }}></OptionImage>
                        <OptionName>Conferences/events</OptionName>
                    </OptionButton>
                    <OptionButton
                        selected={selectedOptions.includes("academic-reports") ? true : false}
                        onClick={() => handleOptionClick("presentationsType", "academic-reports")}
                        fadeOut={fadeOut}
                        style={{
                            border: selectedOptions.includes("academic-reports") ? "2px solid #11A9E2" : "0.5px solid #999"
                        }}
                        delay={calculateDelay(3)}
                    >
                        <OptionImage style={{ fontSize: "20px" }}>science</OptionImage>
                        <OptionName>Academic reports</OptionName>
                    </OptionButton>
                    <OptionButton
                        selected={selectedOptions.includes("lectures") ? true : false}
                        onClick={() => handleOptionClick("presentationsType", "lectures")}
                        fadeOut={fadeOut}
                        style={{
                            border: selectedOptions.includes("lectures") ? "2px solid #11A9E2" : "0.5px solid #999"
                        }}
                        delay={calculateDelay(4)}
                    >
                        <OptionImage style={{ fontSize: "20px" }}>present_to_all</OptionImage>
                        <OptionName>Lectures</OptionName>
                    </OptionButton>
                    <OptionButton
                        selected={selectedOptions.includes("instructional-material") ? true : false}
                        onClick={() => handleOptionClick("presentationsType", "instructional-material")}
                        fadeOut={fadeOut}
                        style={{
                            border: selectedOptions.includes("instructional-material") ? "2px solid #11A9E2" : "0.5px solid #999",
                            ...(!app.isConstrained && {
                                gridColumn: "1 / 3",
                                gridRow: "2",
                            })
                        }}
                        delay={calculateDelay(5)}
                    >
                        <OptionImage as={SchoolOutlined}></OptionImage>
                        <OptionName>Instructional material</OptionName>
                    </OptionButton>
                    <OptionButton
                        selected={selectedOptions.includes("personal-use") ? true : false}
                        onClick={() => handleOptionClick("presentationsType", "personal-use")}
                        fadeOut={fadeOut}
                        style={{
                            border: selectedOptions.includes("personal-use") ? "2px solid #11A9E2" : "0.5px solid #999",
                            ...(!app.isConstrained && {
                                gridColumn: "2 / 4",
                                gridRow: "2",
                            })
                        }}
                        delay={calculateDelay(5)}
                    >
                        <OptionImage as={PersonOutlineOutlined}></OptionImage>
                        <OptionName>Personal use</OptionName>
                    </OptionButton>
                </OptionsContainer>
            )}
            <CompleteButton
                variant="contained"
                color="primary"
                disabled={isCompleteButtonDisabled}
                onClick={() => handleCompleteButtonClick()}
                fadeOut={fadeOut}
                style={{
                    visibility: isCompleteButtonDisabled ? "hidden" : "visible"
                }}
            >
                COMPLETE
            </CompleteButton>
        </Container>
    );
};

export default PresentationsType;
