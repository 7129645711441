import React from "react";
import { CircularProgress, Button } from "@material-ui/core";
import styled from "styled-components";

const ProgressButtonWrapper = styled.div`
    position: relative;
`;

const ButtonProgress = styled(CircularProgress)`
    color: #11a9e2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
`;

export function ProgressButton(props) {
    return (
        <ProgressButtonWrapper>
            <Button {...props} disabled={props.loading || props.disabled} loading={undefined} />
            {props.loading && <ButtonProgress size={24} />}
        </ProgressButtonWrapper>
    );
}
