import { Button } from "@material-ui/core";
import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import { getStaticUrl } from "js/config";
import { trackActivity } from "js/core/utilities/utilities";
import db from "js/db";
import { BeautifulDialog } from "js/react/components/Dialogs/BaseDialog";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";

import Container from "./MarketingSurveyQuestions/Components/Container";

const blueCheckIcon = getStaticUrl("/images/onboarding/check.png");

const StyledDialogTitle = styled.div`
    text-align: center;
    color: #222;
    font-family: "Source Sans Pro";
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%;
    letter-spacing: -0.26px;
    margin-top: 102px;
`;

const StyledDialogContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 39px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
        padding: 0 20px;
        align-items: center;
    }
`;

const PlanBox = styled.div`
    display: flex;
    width: 322px;
    height: auto;
    padding: 40px 48px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;
    border: 1px solid #EEE;
    margin-top: 39px;
`;

const PlanRow = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
`;

const PlanName = styled.div`
    color: #000;
    font-family: "Source Sans Pro";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.48px;
`;

const PlanIntent = styled.div`
    display: flex;
    padding: 3px 8px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 1px;
    background: rgba(17, 169, 226, 0.15);
    color: #11A9E2;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
`;

const PlanPrice = styled.div`
    color: #000;
    font-family: "Source Sans Pro";
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.96px;
    margin-top: 14px;
    margin-bottom: 20px;
`;

const PaddedButton = styled(Button)`
    && {
        display: flex;
        padding: 10px 30px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 1px;
        background: #11A9E2;
        color: #FFF;
        text-align: center;
        font-family: "Source Sans Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        margin-bottom: 40px;

        &:hover {
            background-color: #2980b9;
        }
    }
`;

const PlanMore = styled.div`
    fcolor: #000;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: 0.5px;
    white-space: nowrap;
    margin-bottom: 16px;
`;

const FeatureList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
    align-self: flex-start;

    li {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        color: #000;
        font-family: "Source Sans Pro";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
        letter-spacing: 0.5px;
        white-space: nowrap;

        &::before {
            content: "";
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 10px;
            background-image: url(${blueCheckIcon});
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
`;

const HiddenButton = styled.div`
  display: block;
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 3px;
  height: 3px;
  opacity: 0;
`;

export const ChoosePlan = withRouter(withFirebaseUser(class ChoosePlan extends React.Component {
    saveConfirm = () => db.getDbInstance("users").ref(`users/${this.props.firebaseUser.uid}`).update({ didConfirmPlan: true });

    handlePro = async () => {
        const { history } = this.props;

        await this.saveConfirm();

        trackActivity("SignupFlow", "NavForward", null, null, {
            step_number: 7,
            step_name: "Choose Plan",
            plan_selected: "pro",
            experiment_group_assignment: "B",
            object: "button",
            object_label: "Pro",
            action: "clicked",
            workspace_id: "all"
        }, { audit: true });

        setTimeout(() => {
            // Checkout and then come back
            const continueUrl = window.location.pathname + window.location.search;
            history.push("/checkout?plan=pro&billingTerm=year&continue=" + encodeURIComponent(continueUrl || "/"));
        }, 750);
    }

    handleTeam = async () => {
        const { history } = this.props;

        await this.saveConfirm();

        trackActivity("SignupFlow", "NavForward", null, null, {
            step_number: 7,
            step_name: "Choose Plan",
            plan_selected: "team",
            experiment_group_assignment: "B",
            object: "button",
            object_label: "Team",
            action: "clicked",
            workspace_id: "all"
        }, { audit: true });

        setTimeout(() => {
            // Checkout and then come back
            const continueUrl = window.location.pathname + window.location.search;
            history.push("/checkout?plan=team&billingTerm=year&continue=" + encodeURIComponent(continueUrl || "/"));
        }, 750);
    }

    handleBasic = async () => {
        const { onFinished } = this.props;

        await this.saveConfirm();

        setTimeout(() => {
            setTimeout(() => {
                onFinished();
            }, 750);
        }, 750);
    }

    render() {
        return (
            <Container prevProgress={64} progress={80}>
                <BeautifulDialog
                    PaperProps={{
                        style: {
                            backgroundColor: "transparent",
                            boxShadow: "none"
                        },
                    }}
                    BackdropProps={{
                        style: {
                            backgroundColor: "transparent",
                        },
                    }}
                    preventClose={true}
                    style={{
                        overflowY: "auto"
                    }}
                >
                    <StyledDialogTitle>
                        Choose the plan that's right for you
                    </StyledDialogTitle>
                    <StyledDialogContent>
                        <PlanBox>
                            <PlanRow>
                                <PlanName>Pro</PlanName>
                                <PlanIntent>FOR INDIVIDUALS</PlanIntent>
                            </PlanRow>
                            <PlanPrice>$12/mo.</PlanPrice>
                            <PaddedButton
                                id="choose-pro"
                                variant="contained"
                                color="primary"
                                onClick={this.handlePro}
                            >
                                TRY IT FREE
                            </PaddedButton>
                            <PlanMore>Everything for professionals:</PlanMore>
                            <FeatureList>
                                <li>Version History</li>
                                <li>Powerpoint Export</li>
                                <li>Viewer Analytics</li>
                            </FeatureList>
                        </PlanBox>
                        <PlanBox>
                            <PlanRow>
                                <PlanName>Team</PlanName>
                                <PlanIntent>FOR COMPANIES</PlanIntent>
                            </PlanRow>
                            <PlanPrice>$40/mo.</PlanPrice>
                            <PaddedButton
                                id="choose-team"
                                variant="contained"
                                color="primary"
                                onClick={this.handleTeam}
                            >
                                TRY IT FREE
                            </PaddedButton>
                            <PlanMore>Everything in Pro, plus:</PlanMore>
                            <FeatureList>
                                <li>Collaborative Workspace</li>
                                <li>Custom Company Theme</li>
                                <li>PowerPoint Import</li>
                                <li>Slide and Template Library</li>
                            </FeatureList>
                        </PlanBox>
                        <HiddenButton
                            id="choose-basic"
                            variant="contained"
                            onClick={this.handleBasic}
                        />
                    </StyledDialogContent>
                </BeautifulDialog>
            </Container>
        );
    }
}));
