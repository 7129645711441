import React, { useState, useEffect } from "react";
import styled from "styled-components";

const ProgressBarContainer = styled.div`
    width: 100%;
    background-color: #e0e0e0;
`;

const Progress = styled.div`
    width: ${props => props.progress}%;
    height: 3px;
    background-color: #11A9E2;
    transition: width 0.6s linear;
`;

const ProgressBar = ({ prevProgress, progress }) => {
    const [currentProgress, setCurrentProgress] = useState(prevProgress);

    useEffect(() => {
        setCurrentProgress(progress);
    }, [progress]);

    return (
        <ProgressBarContainer>
            <Progress progress={currentProgress} />
        </ProgressBarContainer>
    );
};

export default ProgressBar;
