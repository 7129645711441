import { keyframes, css } from "styled-components";

const fadeInKeyframes = keyframes`
    from {
        opacity: 0;
        transform: translateX(40%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

const fadeOutKeyframes = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;

export const headerContainerStyles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const headerTextStyles = css`
    color: #222222;
    font-family: "Source Sans Pro";
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%;
    letter-spacing: -0.26px;
    opacity: 0;
    animation: ${props => props.fadeOut ? css`${fadeOutKeyframes} 0.5s ease-in-out` : css`${fadeInKeyframes} 0.6s cubic-bezier(0.2, 0, 0, 1) forwards`};
    animation-delay: ${props => props.delay && !props.fadeOut ? `${props.delay}s` : "0s"};
    animation-fill-mode: forwards;
    will-change: opacity;
`;

export const subHeaderTextStyles = css`
    color: #999999;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: 0.5px;
    margin-top: 5px;
    opacity: 0;
    animation: ${props => props.fadeOut ? css`${fadeOutKeyframes} 0.5s ease-in-out` : css`${fadeInKeyframes} 0.6s cubic-bezier(0.2, 0, 0, 1) forwards`};
    animation-delay: ${props => props.delay && !props.fadeOut ? `${props.delay}s` : "0s"};
    animation-fill-mode: forwards;
    will-change: opacity;
`;

export const optionsContainerStyles = css`
    display: grid;
    gap: 16px;
    justify-items: center;
    align-items: center;
`;

export const optionsButtonStyles = css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 255px;
    height: 63px;
    border-radius: 1px;
    background: #FFF;
    opacity: 0;
    animation: ${props => props.fadeOut ? css`${fadeOutKeyframes} 0.5s ease-in-out` : css`${fadeInKeyframes} 0.6s cubic-bezier(0.2, 0, 0, 1) forwards`};
    animation-delay: ${props => props.delay && !props.fadeOut ? `${props.delay}s` : "0s"};
    animation-fill-mode: forwards;
    will-change: opacity;

    @media (max-width: 768px) {
        width: 164px;
    }
`;

export const optionImageStyles = css`
    position: absolute;
    top: 0;
    left: 0; 
    margin: 10px 0 0 10px;
    color: #11A9E2;
    font-size: 20px;
`;

export const optionNameStyles = css`
    position: absolute;
    color: #999999;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none;
    text-align: left;
    margin-top: 20px;
`;
