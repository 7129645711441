import { DEFAULT_PROGRESS_DURATION_MS, TextFocusType, UndoType } from "common/constants";
import { computeChangeSet } from "common/utils/changeset";
import getLogger, { LogGroup } from "js/core/logger";
import { ds } from "js/core/models/dataService";
import NotificationsService from "js/core/services/notifications";
import presentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";
import PresentationEditorController, { PanelType } from "js/editor/PresentationEditor/PresentationEditorController";
import { app } from "js/namespaces";
import { ShowDialog, ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import ProgressDialog from "js/react/components/Dialogs/ProgressDialog";
import { Backbone, _ } from "js/vendor";

const logger = getLogger(LogGroup.UNDO);

let progressDialog;
function showProgressDialog() {
    if (progressDialog) {
        return progressDialog;
    }

    progressDialog = ShowDialog(ProgressDialog);
}

function closeProgressDialog() {
    if (progressDialog) {
        progressDialog.close();
    }
    progressDialog = null;
}

/**
 * Base class for an undo-able command.
 * Note - This needs to always pull for the object it is modifying instead of maintaining internal reference to the
 * object. This will allow commands to work in a state where the original object may no longer be the same original
 * object.
 */
class Command {
    /**
     *
     * @param {string} objectId - The object id of the slide/presentation to modify with this command.
     * @param oldState - the original value from a changeset call or the original object state.
     * @param newState - the update value from a changeset call or the update object state.
     */
    constructor(objectId, oldState, newState, options = {}) {
        this.objectId = objectId;
        this.oldState = _.cloneDeep(oldState);
        this.newState = _.cloneDeep(newState);
        this.options = options;
    }

    get switchesCurrentSlide() {
        return false;
    }

    getSlide() {
        return this.getPresentation().slides.get(this.objectId);
    }

    getPresentation() {
        return this.options.presentation;
    }

    undo(nextCommands = []) {
        throw new Error("Command.undo not implemented");
    }

    redo(nextCommands = []) {
        throw new Error("Command.undo not implemented");
    }
}

/**
 * A command for modifying slide data.
 */
class ModifySlideCommand extends Command {
    async _update({ attributes, focusedBlockId, selectedElementUniquePath, focusedBlockSelectionState }) {
        const slide = this.getSlide();
        if (!slide) {
            return handleError(removeUndoStackCommands);
        }

        if (PresentationEditorController.currentSlide !== slide) {
            await PresentationEditorController.setCurrentSlide(slide);
        }

        const canvasController = PresentationEditorController.getCurrentCanvasController();
        const selectionLayerController = PresentationEditorController.getCurrentSelectionLayerController();

        const updateSlide = async (silent, isRetry = false) => {
            slide.update(attributes, { removeMissingKeys: true, silent });
            try {
                await slide.updatePromise;
                presentationEditorController.setSelectedPropertyPanelTab("element");
            } catch (err) {
                if (err.status === 409 && !isRetry) {
                    // Just retry on 409s, the slide adater will get the in sync
                    // automatically
                    return updateSlide(silent, true);
                }
                throw err;
            }
        };

        if (
            attributes.version !== slide.get("version") ||
            attributes.migrationVersion !== slide.get("migrationVersion") ||
            attributes.template_id !== slide.get("template_id")
        ) {
            showProgressDialog();
            await updateSlide(true);
            await canvasController.reloadCanvas();
            closeProgressDialog();
            return;
        }

        if (this.options.clearLayout) {
            canvasController.canvas.layouter.clear();
        }
        await updateSlide(false);

        if (!selectedElementUniquePath) {
            return;
        }

        const element = canvasController.canvas.getElementByUniquePath(selectedElementUniquePath);
        if (!element) {
            return;
        }

        await selectionLayerController.setSelectedElements([element]);

        if (!focusedBlockId) {
            return;
        }

        const block = element.blockContainerRef.current?.blockRefs[focusedBlockId]?.current;
        const textEditor = element.uiRefs.selectionRef.current?.textEditorRef.current;
        if (block && textEditor) {
            await textEditor.focusBlock(
                block,
                TextFocusType.POSITION,
                focusedBlockSelectionState?.start ?? 0,
                (focusedBlockSelectionState?.end ?? 0) - (focusedBlockSelectionState?.start ?? 0)
            );
        }
    }

    undo() {
        return this._update(this.oldState);
    }

    redo() {
        return this._update(this.newState);
    }

    toString() {
        return "ModifySlideCommand:" + this.objectId;
    }
}

/**
 * A command for modifying presentation data.
 */
class ModifySlideOrderCommand extends Command {
    async undo() {
        const presentation = this.getPresentation();

        presentation.update({ slideRefs: this.oldState.slideRefs });
        await presentation.updatePromise;

        await presentation.onSlideRefChange(presentation, presentation.get("slideRefs"));

        this.oldState.movedSlides.forEach(slideId => {
            NotificationsService.notifyOnSlideMoved(this.getPresentation().id, slideId)
                .catch(err => logger.error(err, "[ModifySlideOrderCommand] NotificationsService.notifyOnSlideMoved() failed", { presentationId: this.getPresentation().id, slideId }));
        });
    }

    async redo() {
        const presentation = this.getPresentation();

        presentation.update({ slideRefs: this.newState.slideRefs });
        await presentation.updatePromise;

        await presentation.onSlideRefChange(presentation, presentation.get("slideRefs"));

        this.newState.movedSlides.forEach(slideId => {
            NotificationsService.notifyOnSlideMoved(this.getPresentation().id, slideId)
                .catch(err => logger.error(err, "[ModifySlideOrderCommand] NotificationsService.notifyOnSlideMoved() failed", { presentationId: this.getPresentation().id, slideId }));
        });
    }

    toString() {
        return "ModifySlideOrderCommand:" + this.getPresentation().id;
    }
}

async function createSlide(command, state, silent = false) {
    const presentation = command.getPresentation();

    const slideState = _.cloneDeep(state.slideData);
    slideState.id = command.objectId;

    if (silent) {
        await presentation.addExistingSlides(slideState, state.index, { skipUndo: true, silent: true });
        return;
    }

    const createdSlides = await presentation.addExistingSlides(slideState, state.index, { skipUndo: true });

    const slideIndex = presentation.getSlideIndex(createdSlides[0].id);
    await PresentationEditorController.setCurrentSlideByIndex(slideIndex);
}

async function destroySlide(command, state, silent = false) {
    const presentation = command.getPresentation();

    const slide = command.getSlide();
    if (!slide) {
        return handleError(removeUndoStackCommands);
    }

    if (silent) {
        await presentation.destroySlides(slide.id, { skipUndo: true, silent: true });
        return;
    }

    const prevSlideIndex = state.index === 0 ? 1 : state.index - 1;
    await PresentationEditorController.setCurrentSlideByIndex(prevSlideIndex);
    // Nice and smooth transition
    await new Promise(resolve => setTimeout(resolve, 350));

    await presentation.destroySlides(slide.id, { skipUndo: true });
}

/**
 * A command for deleting a slide.
 */
class DeleteSlideCommand extends Command {
    get switchesCurrentSlide() {
        return true;
    }

    undo(nextCommands = []) {
        return createSlide(this, this.oldState, nextCommands.some(command => command.switchesCurrentSlide));
    }

    redo(nextCommands = []) {
        return destroySlide(this, this.oldState, nextCommands.some(command => command.switchesCurrentSlide));
    }

    toString() {
        return "DeleteSlideCommand:" + this.objectId;
    }
}

/**
 * A command for creating a slide.
 */
class CreateSlideCommand extends Command {
    get switchesCurrentSlide() {
        return true;
    }

    undo(nextCommands = []) {
        return destroySlide(this, this.newState, nextCommands.some(command => command.switchesCurrentSlide));
    }

    redo(nextCommands = []) {
        return createSlide(this, this.newState, nextCommands.some(command => command.switchesCurrentSlide));
    }

    toString() {
        return "CreateSlideCommand:" + this.objectId;
    }
}

/**
 * A command that is a collection of many commands.
 */
class CommandGroup {
    constructor(commands) {
        this.commands = commands;
    }

    undo() {
        let chain = Promise.resolve();
        for (let i = this.commands.length - 1; i >= 0; i--) {
            chain = chain.then(() => this.commands[i].undo(this.commands.slice(0, i).reverse()));
        }
        return chain;
    }

    redo() {
        let chain = Promise.resolve();
        for (let i = 0; i < this.commands.length; i++) {
            chain = chain.then(() => this.commands[i].redo(this.commands.slice(i + 1)));
        }
        return chain;
    }

    toString() {
        return "CommandGroup:\n  " + this.commands.join("\n  ");
    }

    get oldState() {
        return this.commands[0]?.oldState;
    }

    get newState() {
        return this.commands[0]?.newState;
    }
}

async function removeUndoStackCommands() {
    app.undoManager.undoStack = app.undoManager.undoStack.filter(command => {
        if (command instanceof ModifySlideCommand) {
            return command.getSlide();
        }
    });
    app.undoManager.stackPosition = app.undoManager.undoStack.length - 1;
    app.undoManager.trigger("undoStackChanged", app.undoManager.stackPosition);
}

async function reloadPage() {
    location.reload();
}

async function handleError(callback) {
    await ShowErrorDialog({
        title: "We can't make that change",
        message: "The item you tried to edit may no longer exist. We've made the latest available update instead.",
        onClose: () => {
            return callback();
        },
        acceptOnClose: true
    });
}

/**
 * The public facing undo manager that handles keeping track of the undo/redo stack and simplifies how commands are created.
 */
class UndoManager {
    constructor() {
        _.extend(this, Backbone.Events);

        this.undoStack = [];
        this.stackPosition = -1;
        this.undoGroupIsOpen = false;
        this.enabled = true;
        this.isUndoing = false;
    }

    openGroup() {
        if (this.undoGroupIsOpen) {
            logger.warn("Trying to open group, but undo group is already open");
            return handleError(reloadPage);
        } else {
            this.undoGroupIsOpen = true;
            this.undoGroup = [];
        }
    }

    closeGroup() {
        if (!this.undoGroupIsOpen) {
            logger.warn("Trying to close group, but no undo group is open");
            return handleError(reloadPage);
        } else {
            this.undoGroupIsOpen = false;
            if (this.undoGroup.length > 0) {
                this.pushCommand(new CommandGroup(this.undoGroup));
            }
        }
    }

    pushCommand(command) {
        const oldCopy = _.cloneDeep(command.oldState) || {};
        const newCopy = _.cloneDeep(command.newState) || {};

        delete oldCopy.focusedBlockId;
        delete oldCopy.focusedBlockSelectionState;
        delete newCopy.focusedBlockId;
        delete newCopy.focusedBlockSelectionState;

        // Skip if there is no data update
        const changeSet = computeChangeSet(oldCopy, newCopy, true);
        if (!changeSet.hasUpdates) {
            return;
        }

        if (this.undoGroupIsOpen) {
            this.undoGroup.push(command);
        } else {
            this.undoStack = _.dropRight(this.undoStack, this.undoStack.length - this.stackPosition - 1);
            this.undoStack.push(command);
            this.stackPosition = this.undoStack.length - 1;
        }

        this.trigger("undoStackChanged", this.stackPosition);
    }

    set(type, objectId, oldState, newState, options = {}) {
        let command;
        switch (type) {
            case UndoType.SLIDE_DATA:
                command = new ModifySlideCommand(objectId, oldState, newState, options);
                break;
            case UndoType.DELETE_SLIDE:
                command = new DeleteSlideCommand(objectId, oldState, newState, options);
                break;
            case UndoType.ADD_SLIDE:
                command = new CreateSlideCommand(objectId, oldState, newState, options);
                break;
            case UndoType.SLIDE_ORDER:
                command = new ModifySlideOrderCommand(objectId, oldState, newState, options);
                break;
            default:
                logger.error(new Error(`Unsupported undo type ${type}`), `Unsupported undo type ${type}`);
                return;
        }
        this.pushCommand(command);
    }

    async _getCommandAndCheckIfCanExecute(stackPosition) {
        if (!this.enabled) {
            return null;
        }

        const command = this.undoStack[stackPosition];
        if (!command) {
            return null;
        }

        if (command instanceof ModifySlideCommand) {
            const canvasController = PresentationEditorController.getCurrentCanvasController();
            if (!canvasController || !canvasController.canvas) {
                return null;
            }

            const slide = command.getSlide();
            if (!slide) {
                return null;
            }

            // Special case to allow undoing when chart panel is open (for current slide)
            const isChartEditorOpen =
                PresentationEditorController.activePanel === PanelType.ELEMENT &&
                PresentationEditorController.elementPanelElement?.isInstanceOf("Chart") &&
                slide.id === canvasController.slide.id;

            if (!canvasController.canvas.isEditable && !isChartEditorOpen) {
                return null;
            }
        }

        return command;
    }

    async _undo() {
        const command = await this._getCommandAndCheckIfCanExecute(this.stackPosition);
        if (!command) {
            return false;
        }

        this.stackPosition--;

        await command.undo();

        this.trigger("undo", command);

        return true;
    }

    async _redo() {
        const command = await this._getCommandAndCheckIfCanExecute(this.stackPosition + 1);
        if (!command) {
            return false;
        }

        this.stackPosition++;

        await command.redo();

        this.trigger("redo", command);

        return true;
    }

    undo() {
        this.isUndoing = true;
        const timeoutId = setTimeout(() => {
            showProgressDialog();
        }, DEFAULT_PROGRESS_DURATION_MS);

        return this._undo().then(data => {
            clearTimeout(timeoutId);
            closeProgressDialog();
            this.isUndoing = false;
            return data;
        });
    }

    redo() {
        this.isUndoing = true;
        const timeoutId = setTimeout(() => {
            showProgressDialog();
        }, DEFAULT_PROGRESS_DURATION_MS);

        return this._redo().then(data => {
            clearTimeout(timeoutId);
            closeProgressDialog();
            this.isUndoing = false;
            return data;
        });
    }

    reset() {
        this.undoStack = [];
        this.stackPosition = -1;
        this.trigger("reset");
    }
}

export default UndoManager;
