import React, { useState } from "react";
import styled, { css, keyframes } from "styled-components";

import ProgressBar from "js/react/components/ProgressBar";

import Header from "./Header";

const fadeInKeyframes = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const fadeOutKeyframes = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;

const PaneContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: #FFF;
    animation: ${props => props.fadeOut ? css`${fadeOutKeyframes} 0.5s ease-in-out` : props.fadeIn ? css`${fadeInKeyframes} 0.6s cubic-bezier(0.2, 0, 0, 1) forwards` : ""};
`;

const ProgressBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 256px);
    height: 3px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), #FFF;
    margin-top: 68px;

    @media (max-width: 768px) {
        width: calc(100% - 20px);
    }
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-top: 150px;
    animation: ${props => props.fadeOut ? css`${fadeOutKeyframes} 0.5s ease-in-out` : props.fadeIn ? css`${fadeInKeyframes} 0.6s cubic-bezier(0.2, 0, 0, 1) forwards` : ""};
`;

function Container({
    showBackButton = false,
    onBackButtonClick = () => { },
    fadeOut = false,
    fadeIn = false,
    contentFadeOut = false,
    contentFadeIn = false,
    prevPaneIdx,
    progress,
    prevProgress,
    children
}) {
    const [prevShowBackButton] = useState(() => {
        return prevPaneIdx >= 2;
    });

    return (
        <PaneContainer fadeOut={fadeOut} fadeIn={fadeIn}>
            <Header showBackButton={showBackButton} prevShowBackButton={prevShowBackButton} onBackButtonClick={onBackButtonClick} />
            <ProgressBarContainer>
                <ProgressBar progress={progress} prevProgress={prevProgress} />
            </ProgressBarContainer>
            <ContentContainer fadeOut={contentFadeOut} fadeIn={contentFadeIn}>
                {children}
            </ContentContainer>
        </PaneContainer>
    );
}

export default Container;
